export default {
  proxy_path: "https://qhptjcpnsk.execute-api.ap-southeast-2.amazonaws.com/api",
  proxy_path_geojson_api: "https://peeuw5qf1d.execute-api.ap-southeast-2.amazonaws.com/api",
  google_api_key: "AIzaSyCYOofv7ePg35vhHG6jVcyRqHmVvUPXRLU",
  main_company_term_id: "2277",
  crm_base_url: "newcrest.forwoodsafety.com",
  custom_tile_url: "https://mapoverlays.geoeditor.newcrest.forwoodsafety.com",
  MAP_REPORT_ACCESS_ROLE: "MapReportAccess",
  forwoodId: {
    URL: "https://id.newcrest.forwoodsafety.com",
    APP_CLIENT_ID: "6l7b5tbf7gdcc8o0d41uscni3m",
  },
  apiGateway: {
    REGION: "ap-southeast-2",
    URL: "https://t5u361xebj.execute-api.ap-southeast-2.amazonaws.com/prod",
  },
  reactApp: {
    HOSTNAME: "https://mapreport.newcrest.forwoodsafety.com",
  },
  get forwoodIdAuthUrl() {
    return `${
      this.forwoodId.URL
    }/authorize?response_type=token&scope=openid&client_id=${
      this.forwoodId.APP_CLIENT_ID
    }&redirect_uri=${this.reactApp.HOSTNAME}&state=`;
  },
};
